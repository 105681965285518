<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">

			<b-link class="brand-logo">
				<b-img
					:src="appLogoImage"
					alt="logo"
					width="220"
				/>
			</b-link>

			<b-col
				lg="8"
				class="d-none d-lg-flex align-items-center p-5"
			>
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img
						fluid
						:src="imgUrl"
						alt="Forgot password V2"
					/>
				</div>
			</b-col>

			<b-col
				lg="4"
				class="d-flex align-items-center auth-bg px-2 p-lg-5"
			>
				<b-col
					sm="8"
					md="6"
					lg="12"
					class="px-xl-2 mx-auto"
				>

					<b-card-title
						title-tag="h2"
						class="font-weight-bold mb-1"
					>
						¿Olvidaste tu contraseña? 🔒
					</b-card-title>
					<b-card-text class="mb-2">
						Ingresa tu correo electrónico y te enviaremos instrucciones para restablecer tu contraseña
					</b-card-text>

					<validation-observer ref="forgetPasswordForm">

						<b-form class="auth-forgot-password-form mt-2">

							<!-- EMAIL -->
                            <div class="mb-2">
                                <label for="email"><strong>CORREO ELECTRÓNICO</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="email"
                                        v-model="userEmail"
                                        name="login-email"
                                        autocomplete="login-email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="john@example.com"
                                        autofocus
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

							<!-- BUTTON SUBMIT -->
							<b-button-loading 
                                text="ENVIAR ENLACE DE REINICIO" 
                                :processing="processing"
                                :block="true"
                                @process-action="forgotPassword">
                            </b-button-loading>

						</b-form>

					</validation-observer>

					<p class="text-center mt-2">
						<b-link :to="{ name: 'login' }">
							<feather-icon icon="ChevronLeftIcon" /> Atrás para iniciar sesión
						</b-link>
					</p>

				</b-col>
			</b-col>

		</b-row>
  	</div>
</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
	import { ValidationProvider, ValidationObserver } from 'vee-validate';
	import { required, email } from '@validations';
	import { $themeConfig } from '@themeConfig';
	import useJwt from '@/auth/jwt/useJwt';
	import store from '@/store/index';

	export default {
		components: {
			ValidationProvider,
			ValidationObserver,

			BButtonLoading
		},
		data() {
			return {
				sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
				processing: false,

				userEmail: '',
				
				required,
				email
			}
		},
		computed: {
			imgUrl() {
				if (store.state.appConfig.layout.skin === 'dark') 
				{
					this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg');
					return this.sideImg;
				}

				return this.sideImg;
			}
		},
		methods: {
			forgotPassword() {
				this.$refs.forgetPasswordForm.validate().then(success => {
                    if (success) {

						this.processing = true;

						useJwt.forgetPassword({
                            email: this.userEmail
                        })
                        .then(response => {
                            if (response.data.success) {
                                
                                this.$toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

                            }
                        })
                        .catch(error => this.$refs.forgetPasswordForm.setErrors({ email: [error.response.data.message] }))
                        .finally( () => this.processing = false);

					}
                });
			}
		},
		setup() {
			const { appLogoImage } = $themeConfig.app;

			return {
				appLogoImage
			}
		}
	}

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
